<template>
        <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <v-breadcrumbs :items="breadcumbs">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item
                                    :to="item.href"
                                    class="text-subtitle-2 crumb-item"
                                    :disabled="item.disabled"
                                    exact
                                >
                                    {{ item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-col>
                    <v-col cols="12">
                        <v-card>
                            <v-container fluid>
                                <v-row>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="IdModel" 
                                    :items="Datatable" 
                                    default="" 
                                    item-value="CustomerId" 
                                    item-text="CustomerId" 
                                    label="Customer ID" 
                                    clearable 
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        clearable 
                                        v-model="date_from"
                                        label="Start Due Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-menu
                                    ref="modal"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        clearable
                                        label="End Due Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal2 = false"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                 <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-btn class="mt-3" 
                                    color="info" elevation="2" large @click="SearchData()">Search</v-btn>
                                </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Result
                                <v-alert
                                    text
                                    color="primary"
                                    class="ml-4 mb-0 rounded-l"
                                    v-show="display_alert"
                                    >
                                    <v-row
                                        align="center"
                                        no-gutters
                                        >
                                    </v-row>
                                </v-alert>
                                    <v-spacer></v-spacer>
                                    <h7 style="color: red;">
                                    Note :  Cyan color has not been sent Wa -
                                    </h7>
                                     <h7 style="margin-left:5px; color: black;" >
                                    Red color has been sent Wa
                                    </h7>
                                </v-card-title>
                                 <v-data-table
                                    v-model="selected"
                                    :headers="isitable"
                                    :items="Datatable"
                                    :options.sync="options"
                                    class="elevation-1"
                                >
                                   <template v-slot:[`item.send`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <div v-if="item.respon == 'N' || item.respon == ''">
                                                <v-btn
                                                color="cyan" 
                                                dark
                                                style="width: 165px;"
                                                @click="SendWhatsapp(item)" v-on="on">
                                                Send WhatsApp
                                                <v-col cols="2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="35" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                                                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                                            </svg>
                                            </v-col>
                                            </v-btn>
                                            </div>
                                            <div v-else>
                                            <v-btn 
                                                dark
                                                color="red"
                                                style="width: 165px;"
                                                @click="SendWhatsapp(item)" v-on="on">
                                                Send WhatsApp
                                              <v-col class="col-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="35" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                                                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                                            </svg>
                                              </v-col>
                                            </v-btn>
                                            </div>
                                        </template>
                                        <v-col cols="12">
                                        <span style="color:#ffffff">Confirm</span>
                                        </v-col>
                                    </v-tooltip>
                                </template>
                        <template v-slot:[`item.ord_amt`]="{ item }">
                            {{ $store.getters.convertToCurrencyUs(item.ord_amt) }}
                        </template>
                        <template v-slot:[`item.JthTempo`]="{ item }">
                            {{ $store.getters.convertToCurrencyUs(item.JthTempo) }}
                        </template>
                        <template v-slot:[`item.credit_limit`]="{ item }">
                            {{ $store.getters.convertToCurrencyUs(item.credit_limit) }}
                        </template>
                        <template v-slot:[`item.TotFaktur`]="{ item }">
                            {{ $store.getters.convertToCurrencyUs(item.TotFaktur) }}
                        </template>                     
                        </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-col>
                </v-row>
        </v-container>
</template>
<script>
export default {
        data(){
            return{
            breadcumbs: [
                {
                text: 'KMB',
                disabled: false,
                href: '/admin/kmb/dashboard',
                },
                {
                text: 'Outstanding Invoice',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Send WA',
                disabled: true,
                href: 'breadcrumbs_link_2',
                }
            ],
            IdModel: '',
            date_from: '',
            date_to: '',
            options: {},
            modal_to:'',
            modal: false,
            modal2:false,
            modal_to:false,
            display_alert: false,
            singleSelect: false,
            selectOrders: [],
            selected: [],
            Datatable: [],
            isitable: [
                { value: 'CustomerId', text: 'Customer ID'},
                { value: 'NamaCustomer', text: 'Nama Customer'},
                { value: 'Telepon', text: 'Nomer Telepon'},
                { value: 'tglfaktur', text: 'Tgl Faktur'},
                { value: 'TglJTempo', text: 'Due Date'},
                { value: 'piutang', text: 'Piutang'},
                // { value: 'memo_txt', text: 'Tgl Tempo'},
                { value: 'send', text: 'Send WhatsApp'},
            ],
            respon : 'N'
            }
        },
        mounted(){
        this.getIndex()
            },
        methods:{

        closeAlert(){
            this.display_alert = false
        },
        sendAll(){

            Swal.fire({
                text: 'Do you want Send All WhatsApp ?',
                showDenyButton: true,
                confirmButtonText: 'Save',
                denyButtonText: 'No',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {

                    axios.post(`${process.env.VUE_APP_URL}/api/kmb/outstanding/sendAll`, { 

                        list_no_hp : this.Datatable

                    },
                    {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '*',
                                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                            } 
                    })
                    .then(res => {
                        
                        console.log(res.data.result);

                    })

                }
            })

        },
        getIndex(){
            this.$store.dispatch('setOverlay', true)

            this.date_from = this.currentDate()
            this.date_to = this.currentDate()

            axios.get(`${process.env.VUE_APP_URL}/api/kmb/outstanding?Search=${this.$store.state.text ? this.$store.state.text : ''}&start_date=${this.date_from ? this.date_from : ""}&end_date=${this.date_to ? this.date_to : ""}`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
            console.log(res.data)
               this.$store.dispatch('setOverlay', false)
                this.Datatable = res.data.datas
            })
        },

        getData(){
            this.$store.dispatch('setOverlay', true)
            axios.get(`${process.env.VUE_APP_URL}/api/kmb/outstanding/getData`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
            // console.log(res.data)
               this.$store.dispatch('setOverlay', false)
                this.Datatable = res.data.datas
            })
        },
        SearchData(){
            var startDate = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var endDate = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
            axios.get(`${process.env.VUE_APP_URL}/api/kmb/outstanding/getData?Search=${this.$store.state.text ? this.$store.state.text : ''}&CustomerId=${this.IdModel ? this.IdModel : ""}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
            .then(res => {
                console.log(res.data)
                this.Datatable = res.data.datas
            })
        },
       async SendWhatsapp(id){

            var msg = "Pelanggan Kencana, " + id.NamaCustomer + ' Dengan ini kami memberitahukan bahwa Tagihan anda  No Faktur : '  + id.faktur + ' Dan Surat Pesanan : ' + id.CustomerOrderNo + ' Jatuh Tempo Pada Tanggal '+ id.TglJTempo +  ' Sihlakan segera melakukan Pembayaran ke Rekening BCA atas nama PT. Kencana Maju Bersama AC. 0505888800. Mohon Di Infokan kepada kami melalui Selesman, Jika sudah melakukan Pembayaran. Terimakasih (PT. Kencana Maju Bersama)'

            this.$store.dispatch('setOverlay', true)

            const prefixHP = ['0852', '0853','0811', '0812', '0813', '0821', '0822', '0851', '0855', '0856' , '0857', '0858','0814', '0815'
                , '0816' , '0817', '0818', '0819' , '0859', '087700' , '0878' , '0895' , '0896' , '0897' , '0898' , '0899' , '0813', '0832' , '0833' , '0838'
                ,'0881' , '0882' , '0883' , '0884' ,'0885', '0886', '0887', '0888', '0889']

            var prefixCustNo = id.Telepon.substring(0, 4);

            if (prefixHP.indexOf(prefixCustNo) === -1) {

                this.$store.dispatch('setOverlay', false)
                
                Swal.fire({
                        text: 'No ini tidak terdaftar WhatsApp !!!',
                        icon: 'warning',
                    })

                return false
            }

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/outstanding/sendNotifWhatsApp?phone=${id.Telepon}&message=${msg}&noFaktur=${id.faktur}`,{ 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` 
                    } 
            }).then(res => {

                if(res.data.stat == 1){

                    Swal.fire({
                        text: 'WhatsApp Terkirim',
                        icon: 'success',
                    })
                    this.getData()
                    this.$store.dispatch('setOverlay', false)

                } else {

                    this.reportStat = '0'

                    Swal.fire({
                        text: res.data.stat,
                        icon: 'warning',
                    })

                    this.$store.dispatch('setOverlay', false)

                }    

            }).catch(err => {
                    this.$store.dispatch('setOverlay', false)
                console.log(err.response.data.message)
                
            })


        },

        currentDate() {

            const current = new Date();
            const tanggal = current.getDate()
            // console.log('iki lhoo : ' + current.getDate());
            current.setDate(current.getDate() + 20);

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2) + '-' + tanggal
            return date;

        },

        },
        watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display === "block") {
                    this.getData(page, itemsPerPage)
                }
            },
            deep: true,
        }
    
    }    

    }
</script>

<style>

</style>